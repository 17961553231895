import { memo } from 'react';
import { Gallery } from '@divlab/divanui';

import Item from './Item';
import styles from './Subcategories.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { SubcategoryData } from '@Types/Category';

interface SubcategoriesProps extends HTMLAttributes<HTMLDivElement> {
  subcategories?: SubcategoryData[];
}

const Subcategories: FC<SubcategoriesProps> = (props) => {
  const { subcategories = [], ...restProps } = props;

  return (
    <Gallery cnSlide={styles.slide} scrollbar hasEmptyScrollbar {...restProps}>
      {subcategories.map((subcategory) => (
        <Item key={subcategory.id} subcategory={subcategory} />
      ))}
    </Gallery>
  );
};

export default memo(Subcategories);
